import React, { useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import './BatchReportBuilderPage.css';
import InputField from '../../components/FormFields/InputField';
import FloatingSelectField from "../../components/FormFields/FloatingSelectField";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import { AuthContext } from "../../contexts/AuthContext";
import { createBatchJob, startBatchJob } from "../../services/batchReports";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useScrollToError from "../../hooks/useScrollToError";
import useReportLayouts from "../../hooks/useReportLayouts";
import QuanteraLoader from "../../components/LoadingAnimations/QuanteraLoader";

const BatchReportBuilderPage = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { layoutID, setLayoutID, layoutOptions } = useReportLayouts(isAuthenticated);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [jobID, setJobID] = useState('');
  const buttonsRef = useRef(null);
  const [companies, setCompanies] = useState([
    { company_name: '', company_website: '' },
    { company_name: '', company_website: '' },
    { company_name: '', company_website: '' },
    { company_name: '', company_website: '' },
    { company_name: '', company_website: '' }
  ]);

  useScrollToError(buttonsRef, errorMessage);

  const handleCompanyChange = (index, field, value) => {
    const updatedCompanies = [...companies];
    updatedCompanies[index] = {
      ...updatedCompanies[index],
      [field]: value
    };
    setCompanies(updatedCompanies);
  };

  const addCompanyInput = () => {
    setCompanies([...companies, { company_name: '', company_website: '' }]);
  };

  const removeCompanyInput = (index) => {
    const updatedCompanies = companies.filter((_, i) => i !== index);
    setCompanies(updatedCompanies);
  };

  const handleBuildReports = async () => {
    try {
      setJobID('');
      setIsLoadingJob(true);
      setProgressMessage('👷 Building batch job...');
      setErrorMessage('');
      const jobResponse = await createBatchJob({
        companies: companies,
        report_layout_id: layoutID
      });
      setProgressMessage('📅 Scheduling reports...')
      const jobLaunchResponse = await startBatchJob({
        job_id: jobResponse.job_id
      });
      setJobID(jobResponse.job_id);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoadingJob(false);
    }
  };

  return (
    <RequireLogin redirect>
      <PageWrapper title="Batch Report Builder">
        <div className="mb-3">
          <Link to="/report-builder">
            <Button variant="outline" className="me-2">
              <FontAwesomeIcon icon={faChevronRight} className="me-1" /> Single Report Builder
            </Button>
          </Link>
        </div>
        <Card className="mb-3">
          <Card.Header>
            Instructions
          </Card.Header>
          <Card.Body>
            <ol className="mb-0 fw-light lh-16">
              <li>Select the template you wish to use for your reports.</li>
              <li>Enter the names and website URLs for all companies you wish to generate reports for.</li>
              <li>Click <kbd>Build Reports</kbd> to initiate the batch job.</li>
              <li>A link will be provided to a page where you can check the status of your job.</li>
            </ol>
          </Card.Body>
        </Card>
        <div>
          <FloatingSelectField 
            id="selectReportLayout" 
            label="Report Layout" 
            options={layoutOptions} 
            onChange={(e) => setLayoutID(e.target.value)} 
            value={layoutID}
            className="mb-4"
          />

          {companies.map((company, index) => (
            <div key={index} className="company-input-row d-flex align-items-center gap-2">
              <div className="company-input-fields d-flex flex-grow-1 gap-3 mb-3">
                <InputField
                  id={`inputCompanyName-${index}`}
                  type="text"
                  label="Company Name"
                  value={company.company_name}
                  onChange={(e) => handleCompanyChange(index, 'company_name', e.target.value)}
                  showLabel={false}
                  className="flex-grow-1"
                />
                <InputField
                  id={`inputCompanyWebsite-${index}`}
                  type="text"
                  label="Company Website"
                  value={company.company_website}
                  onChange={(e) => handleCompanyChange(index, 'company_website', e.target.value)}
                  showLabel={false}
                  className="flex-grow-1"
                />
                <div className="d-flex align-items-center">
                  {companies.length > 1 && (
                    <Button 
                      variant="outline-dark"
                      className="remove-company-btn"
                      onClick={() => removeCompanyInput(index)}
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}

          <div ref={buttonsRef}>
            {!jobID ? (
              <>
                <Button 
                  variant="outline-dark" 
                  className="mb-4 me-2"
                  onClick={addCompanyInput}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Company
                </Button>

                <Button 
                  variant="dark" 
                  className="mb-4"
                  onClick={handleBuildReports}
                >
                  Build Reports
                </Button>
              </>
            ) : (
              <>
                <h4>🚀 Batch job launched!</h4>
                <Link to={`/reports/job/${jobID}`}>
                  <Button variant="secondary" className="me-2 mt-3">
                    View Job Status
                  </Button>
                </Link>
              </>
            )}
          </div>

          {isLoadingJob && (
            <div className="report-builder-loading-box text-center">
              <QuanteraLoader sizePixels={70} />
              {progressMessage}
            </div>
          )}

          {errorMessage && (
            <Alert variant="danger" className="mb-3">
              {errorMessage}
            </Alert>
          )}

        </div>
      </PageWrapper>
    </RequireLogin>
  );
};

export default BatchReportBuilderPage;