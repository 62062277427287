import React from 'react';
import { Badge } from 'react-bootstrap';

const JobStatusBadge = ({ status }) => (
  <Badge bg={
    status === 'success' ? 'success' :
    status === 'completed' ? 'success' :
    status === 'processing' ? 'warning' :
    status === 'failure' ? 'danger' :
    'secondary' // for 'scheduled' status
  }>
    {status}
  </Badge>
);

export default JobStatusBadge; 