import React, { useState } from 'react';
import './Header.css';
import { useLocation, Link } from 'react-router-dom';
import RequireLogin from '../RequireLogin/RequireLogin';
import { logout } from '../../services/user';
import { 
  faBell, 
  faEye, 
  faCircleDot, 
  faUser, 
  faGear, 
  faRightFromBracket, 
  faChartSimple, 
  faPlus, 
  faFileAlt, 
  faLayerGroup,
  faEnvelopeOpen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from 'react-bootstrap/Badge';
import { getNotifications, markNotificationsSeen } from "../../services/user";
import Button from "react-bootstrap/Button";
import InlineSpinner from "../LoadingAnimations/InlineSpinner";
import { useNotifications } from "../../contexts/NotificationsContext";
import { useActivatedFeatures } from "../../contexts/ActivatedFeaturesContext";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import SearchDealsInput from "../SearchDealsInput/SearchDealsInput";

const Header = () => {
  const location = useLocation(); // Get the current location
  const [notifications, setNotifications] = useState([]);
  const { notificationsCount, setNotificationsCount, notificationsLoaded, setNotificationsLoaded } = useNotifications();
  const { activatedFeatures } = useActivatedFeatures();


  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  const fetchNotifications = async () => {
    try {
      const response = await getNotifications();
      setNotifications(response.notifications);
      setNotificationsCount(response.count);
      setNotificationsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClickNotifications = async () => {
    if (notificationsLoaded) {
      return
    }
    await fetchNotifications();
  }

  const handleMarkNotificationsSeen = async () => {
    if (notificationsCount === 0) {
      return
    }
    try {
      const response = await markNotificationsSeen();
      await fetchNotifications();
    } catch (error) {
      console.log(error);
    }
  }

  // Determine which logo to use - custom or default
  const logo = process.env.REACT_APP_CUSTOM_LOGO_URL || '/logo.svg';

  return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
        <Container fluid="md">
          <div className="logo-search-container">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="..." height="36" />
            </Link>
            <RequireLogin>
              <SearchDealsInput />
            </RequireLogin>
          </div>
          <button className="navbar-toggler flex-item" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <RequireLogin>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto fw-light">
                
                <li className="nav-item">
                  <Link className={`nav-link ${isActive('/') ? 'active' : ''}`} aria-current="page" to="/">Home</Link>
                </li>
                
                <li className="nav-item">
                  <Link className={`nav-link ${isActive('/deals') ? 'active' : ''}`} to="/deals">Deals</Link>
                </li>
                
                <li className="nav-item dropdown">
                  <a className={`nav-link dropdown-toggle ${isActive('/reports') ? 'active' : ''}`} href="#" id="navbarDropdown" role="button"
                     data-bs-toggle="dropdown" aria-expanded="false">
                    Reports
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="navbarDropdown">
                    <li>
                      <Link className="dropdown-item fw-light" to="/reports"><FontAwesomeIcon icon={faFileAlt} className="me-2" /> My Reports</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/reports/jobs"><FontAwesomeIcon icon={faLayerGroup} className="me-2" /> Batch Jobs</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/report-builder"><FontAwesomeIcon icon={faPlus} className="me-2" /> New Report</Link>
                    </li>
                  </ul>
                </li>

                { activatedFeatures.emails === true && (
                <li className="nav-item dropdown">
                  <a className={`nav-link dropdown-toggle ${isActive('/emails') ? 'active' : ''}`} href="#" id="navbarDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Emails
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="navbarDropdown">
                    <li>
                      <Link className="dropdown-item fw-light" to="/emails"><FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> My Emails</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/emails/jobs"><FontAwesomeIcon icon={faLayerGroup} className="me-2" /> Batch Jobs</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/batch-email-builder"><FontAwesomeIcon icon={faPlus} className="me-2" /> New Email</Link>
                    </li>
                  </ul>
                </li>
                ) }

                <li className="nav-item dropdown">
                  <a className={`nav-link dropdown-toggle ${isActive('/account') ? 'active' : ''}`} href="#" id="navbarDropdown" role="button"
                     data-bs-toggle="dropdown" aria-expanded="false">
                    Account
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="navbarDropdown">
                    <li>
                      <Link className="dropdown-item fw-light" to="/account"><FontAwesomeIcon icon={faUser} className="me-2" /> My Account</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/usage"><FontAwesomeIcon icon={faChartSimple} className="me-2" /> Usage Stats</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/settings"><FontAwesomeIcon icon={faGear} className="me-2" /> Settings</Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item fw-light" to="/" onClick={logout}><FontAwesomeIcon icon={faRightFromBracket} className="me-2" /> Logout</Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a href="#" onClick={() => handleClickNotifications()} className="nav-link dropdown-toggle notification-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                     aria-expanded="false" id="notificationDropdown">
                    <FontAwesomeIcon icon={faBell} />
                    {notificationsCount > 0 && (
                      <Badge bg="danger" className="rounded-pill position-absolute top-10 start-90 translate-middle badge-notification">{notificationsCount}</Badge>
                    )}
                  </a>

                  <ul className="dropdown-menu dropdown-menu-end notify-drop shadow-sm" aria-labelledby="notificationDropdown">
                    <div className="notify-drop-title">
                      <div className="row">
                        <div className="small fw-normal">Notifications {notificationsCount > 0 && (<b>({notificationsCount})</b>)}</div>
                      </div>
                    </div>
                    <div className="drop-content">
                      {
                        !notificationsLoaded ? (
                          <li className="text-center"><InlineSpinner /></li>
                        ) : notifications.length === 0 ? (
                          <li><p>No notifications</p></li>
                        ) : (
                          notifications.map((notification, index) => (
                            <li key={index}>
                            {
                              notification.url ? (
                                <Link to={notification.url}>
                                  <Row>
                                    <div className="col-11">
                                      {notification.title}
                                      {notification.message && <p>{notification.message}</p>}
                                      <div className="time">{notification.date}</div>
                                    </div>
                                    <div className="col-1">
                                      {!notification.seen && (
                                        <FontAwesomeIcon icon={faCircleDot} />
                                      )}
                                    </div>
                                  </Row>
                                </Link>
                              ) : (
                                <Row>
                                  <div className="col-11">
                                    {notification.title}
                                    {notification.message && <p>{notification.message}</p>}
                                    <div className="time">{notification.date}</div>
                                  </div>
                                  <div className="col-1">
                                    {!notification.seen && (
                                      <FontAwesomeIcon icon={faCircleDot} />
                                    )}
                                  </div>
                                </Row>
                              )
                            }
                            </li>
                          ))
                        )
                      }
                    </div>
                    <div className="notify-drop-footer text-center">
                      { notifications.length !== 0 && (
                        <Button variant="link" onClick={() => handleMarkNotificationsSeen()}><FontAwesomeIcon icon={faEye} /> Mark as seen</Button>
                      )}
                    </div>
                  </ul>
                </li>

              </ul>
            </div>
          </RequireLogin>
        </Container>
      </nav>
  );
};

export default Header;