import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import Spinner from "../../components/LoadingAnimations/Spinner";
import { getDeal, deleteDeal } from "../../services/companies";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrashCan, faPerson, faPeopleGroup, faTable, faChartLine, faEnvelope, faFileAlt, faInfoCircle,
    faNewspaper, faBackward, faPlus, faStar, faComments
} from '@fortawesome/free-solid-svg-icons';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import EmailGeneratorModal from "../../components/EmailGeneratorModal/EmailGeneratorModal";
import { useActivatedFeatures } from "../../contexts/ActivatedFeaturesContext";
import ColumnPageWrapper from "../../components/PageWrapper/ColumnPageWrapper";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ReportsTable from "../../components/ReportsTable/ReportsTable";
import './DealPage.css';
import EmailsTable from "../../components/EmailsTable/EmailsTable";
import EmailsStatusToggle from "../../components/EmailsTable/EmailsStatusToggle";
import CompanyAboutCard from "../../components/CompanyAboutCard/CompanyAboutCard";
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import NewsList from "../../components/NewsList/NewsList";
import SidebarNavigation from "../../components/SidebarNavigation/SidebarNavigation";
import TransactionsTable from "../../components/TransactionsTable/TransactionsTable";
import PeopleTable from "../../components/PeopleTable/PeopleTable";
import FinancialsTable from "../../components/FinancialsTable/FinancialsTable";
import EmployeeReviewsTable from "../../components/EmployeeReviewsTable/EmployeeReviewsTable";
import HeadcountChart from "../../components/HeadcountChart/HeadcountChart";
import CustomerReviewsTable from "../../components/CustomerReviewsTable/CustomerReviewsTable";

const DealPage = () => {
    const { id } = useParams();
    const [loadingDeal, setLoadingDeal] = useState(true)
    const [dealResponse, setDealResponse] = useState({})
    const [viewSentEmailsOnly, setViewSentEmailsOnly] = useState(0);
    const [emailsStartingPage, setEmailsStartingPage] = useState(1);
    const [showEmailGenerator, setShowEmailGenerator] = useState(false);
    const { activatedFeatures } = useActivatedFeatures();
    const [newEmailRef, setNewEmailRef] = useState(null);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [newsId, setNewsId] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const sectionRefs = {
        generalInfo: useRef(null),
        reports: useRef(null),
        emails: useRef(null),
        news: useRef(null),
        financials: useRef(null),
        transactionHistory: useRef(null),
        keyPeople: useRef(null),
        headcount: useRef(null),
        employeeReviews: useRef(null),
        customerReviews: useRef(null)
    };
    const sidebarRef = useRef(null);

    // Use the `id` query parameter to fetch the report with the specific ID
    useEffect(() => {
        const fetchDeal = async () => {
            try {
                setLoadingDeal(true);
                const response = await getDeal(id);
                setDealResponse(response);
            } catch (error) {
                navigate('/404')
            } finally {
                setLoadingDeal(false);
            }
        };
        if (id) {
            fetchDeal();
        }
        }, [id]
    );

    const scrollToSection = (section) => {
        const sectionRef = sectionRefs[section];
        if (sectionRef.current) {
            window.scrollTo({
                top: sectionRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sidebar = sidebarRef.current;
            if (sidebar) {
                const headerHeight = 56; // Static height of header
                const footerHeight = 57; // Static height of footer + 1
                const scrolledY = window.scrollY;
                const windowHeight = window.innerHeight;

                let topPadding = Math.max(0, headerHeight - scrolledY);
                let bottomPadding = Math.max(0, footerHeight - (document.documentElement.scrollHeight - scrolledY - windowHeight));
                let sidebarOffset = topPadding + bottomPadding;

                sidebar.style.height = `calc(100vh - ${sidebarOffset}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const action = searchParams.get('action');
        if (action === "emails") setTimeout(() =>scrollToSection("emails"), 2000);
    }, [newEmailRef]);

    const handleDeleteDeal = async () => {
        try {
            await deleteDeal(id);
            // Report deleted successfully, go to deal list page
            navigate('/deals')
        } catch (error) {
            console.log(error);
        }
    };

    if (loadingDeal) {
      return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
          <ColumnPageWrapper title="Deal">
            <div ref={sidebarRef} className="deal-sidebar d-flex flex-column flex-shrink-0 py-3 px-2 px-md-3 bg-light small">
                <div className="deal-title fs-6 d-none d-md-inline-block"><strong>Contents</strong></div>
                <div className="deal-title fs-6 d-inline-block d-md-none text-center"><strong>Nav</strong></div>
                <hr />
                <SidebarNavigation
                  sections={{
                  generalInfo: {ref: sectionRefs.generalInfo, label: "General Info", icon: faInfoCircle},
                  reports: {ref: sectionRefs.reports, label: "Reports", icon: faFileAlt},
                  emails: {ref: sectionRefs.emails, label: "Emails", icon: faEnvelope, conditional: activatedFeatures.emails},
                  news: {ref: sectionRefs.news, label: "News", icon: faNewspaper},
                  financials: {ref: sectionRefs.financials, label: "Financials", icon: faChartLine},
                  transactionHistory: {ref: sectionRefs.transactionHistory, label: "Transaction History", icon: faTable},
                  keyPeople: {ref: sectionRefs.keyPeople, label: "Key People", icon: faPerson},
                  headcount: {ref: sectionRefs.headcount, label: "Headcount", icon: faPeopleGroup},
                  employeeReviews: {ref: sectionRefs.employeeReviews, label: "Employee Reviews", icon: faComments},
                  customerReviews: {ref: sectionRefs.customerReviews, label: "Customer Reviews", icon: faStar}
                }}
                  className="deal-page-nav"
                />
                <hr />
                <Link to="/deals" className="text-decoration-none text-center text-md-start">
                    <FontAwesomeIcon icon={faBackward} />
                    <span className="d-none d-md-inline-block ms-2">Back to Deals</span>
                </Link>
            </div>
            <div className="deal-main-content px-4 py-3 small d-flex flex-column flex-grow-1">
                <div className="deal-title fs-6 d-flex justify-content-between">
                    <strong>{dealResponse.name}</strong>
                    <IconTooltipButton position="left" tooltipText="Delete" onClick={() => setShowDeletionModal(true)} className="py-0 px-2">
                        <FontAwesomeIcon icon={faTrashCan} className="small" />
                    </IconTooltipButton>
                </div>
                <hr />

                <Row className="mb-2" ref={sectionRefs.generalInfo}>
                    <Col sm={12}>
                        <h6>General Information</h6>
                        <hr />
                    </Col>
                    { dealResponse.company_id && (
                        <CompanyAboutCard companyId={dealResponse.company_id} showHeader={false} />
                    )}
                </Row>

                <Row className="mb-2" ref={sectionRefs.reports}>
                  <Col className="mb-3">
                    <h6>Reports</h6>
                    <hr />
                      <div className="mb-3">
                          <Button variant="outline-dark" className="py-1"
                                  onClick={() => navigate(`/report-builder?companyName=${dealResponse.name}&companyWebsite=${dealResponse.identifier}`)}>
                              <FontAwesomeIcon icon={faPlus} className="me-1" /> New Report
                          </Button>
                      </div>
                    <div className="deal-table">
                      <ReportsTable reportsPerPage={5} dealId={id} />
                    </div>
                  </Col>
                </Row>

                { activatedFeatures.emails && (
                <Row className="mb-2" ref={sectionRefs.emails}>
                    <Col className="mb-3">
                    <h6>Emails</h6>
                    <hr />
                      <div className="deal-emails-header">
                          <EmailsStatusToggle className="d-inline-block me-2 mb-3" viewSentOnly={viewSentEmailsOnly} setViewSentOnly={setViewSentEmailsOnly} setStartingPage={setEmailsStartingPage} />
                          <Button ref={setNewEmailRef} variant="outline-dark" className="py-1 d-inline-block mb-3" onClick={() => setShowEmailGenerator(true)}>
                              <FontAwesomeIcon icon={faPlus} className="me-1" /> New Email
                          </Button>
                      </div>
                    <div className="deal-table">
                      <EmailsTable dealId={id} viewSentOnly={viewSentEmailsOnly} pageNum={emailsStartingPage} emailsPerPage={5} />
                    </div>
                    </Col>

                    { dealResponse.company_id && (
                        <EmailGeneratorModal show={showEmailGenerator} companyId={dealResponse.company_id} dealId={id} companyName={dealResponse.name} onHide={() => setShowEmailGenerator(false)} />
                    )}
                </Row>
                )}

                <Row className="mb-2" ref={sectionRefs.news}>
                    <Col className="mb-3">
                        <h6>News</h6>
                        <hr />
                        <NewsList page={1} perPage={5} onePerCompany={true} showMoreAt={2} dealId={id} />
                    </Col>
                </Row>

                <Row className="mb-2" ref={sectionRefs.financials}>
                    <Col className="mb-3">
                        <h6>Financials</h6>
                        <hr />
                        { dealResponse.company_id && (
                          <div className="deal-table">
                            <FinancialsTable companyId={dealResponse.company_id} />
                          </div>
                        )}
                    </Col>
                </Row>

                <Row className="mb-2" ref={sectionRefs.transactionHistory}>
                    <Col className="mb-3">
                        <h6>Transaction History</h6>
                        <hr />
                        { dealResponse.company_id && (
                          <div className="deal-table">
                            <TransactionsTable companyId={dealResponse.company_id} />
                          </div>
                        )}
                    </Col>
                </Row>

                <Row className="mb-2" ref={sectionRefs.keyPeople}>
                    <Col className="mb-3">
                        <h6>Key People</h6>
                        <hr />
                        { dealResponse.company_id && (
                          <div className="deal-table">
                            <PeopleTable companyId={dealResponse.company_id} />
                          </div>
                        )}
                    </Col>
                </Row>

                <Row className="mb-2" ref={sectionRefs.headcount}>
                    <Col className="mb-3">
                        <h6>Headcount</h6>
                        <hr />
                        <HeadcountChart companyId={dealResponse.company_id} />
                    </Col>
                </Row>

                <Row className="mb-2" ref={sectionRefs.employeeReviews}>
                    <Col className="mb-3">
                        <h6>Employee Reviews</h6>
                        <hr />
                        { dealResponse.company_id && (
                          <div className="deal-table">
                            <EmployeeReviewsTable companyId={dealResponse.company_id} />
                          </div>
                        )}
                    </Col>
                </Row>

                 <Row className="mb-2" ref={sectionRefs.customerReviews}>
                    <Col className="mb-3">
                        <h6>Customer Reviews</h6>
                        <hr />
                        { dealResponse.company_id && (
                          <div className="deal-table">
                            <CustomerReviewsTable companyId={dealResponse.company_id} />
                          </div>
                      )}
                    </Col>
                </Row>

              <ConfirmationModal show={showDeletionModal}
                                 title="Confirm deletion"
                                 body="This action will delete the deal along with all associated reports and emails. Are you sure you wish to proceed?"
                                 onHide={() => setShowDeletionModal(false)}
                                 onConfirm={() => handleDeleteDeal()}
              />
            </div>
          </ColumnPageWrapper>
        </RequireLogin>
    );
};

export default DealPage;