import React, { useState, useEffect } from 'react';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import { getUsage } from "../../services/user";
import Spinner from "../../components/LoadingAnimations/Spinner";
import './UsagePage.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import  { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import { Chart, CategoryScale, LinearScale, TimeScale, PointElement, BarElement, Tooltip } from "chart.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding, faCoins, faCalendar, faPieChart } from '@fortawesome/free-solid-svg-icons';

Chart.register(CategoryScale, LinearScale, TimeScale, PointElement, BarElement, ChartDataLabels, Tooltip);

const AccountPage = () => {
    const [organization, setOrganization] = useState("")
    const [userReports, setUserReports] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [reportsByUser, setReportsByUser] = useState([]);
    const [reportsByDay, setReportsByDay] = useState({});
    const [monthlyCredits, setMonthlyCredits] = useState(null);
    const [creditPeriod, setCreditPeriod] = useState("");
    const [creditsConsumed, setCreditsConsumed] = useState(0);
    const [creditsConsumedPercent, setCreditsConsumedPercent] = useState(0);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        const fetchUsage = async () => {
            setLoadingData(true);
            try {
                const response = await getUsage({ days: 30 });
                setUserReports(response.user_reports);
                setTotalReports(response.total_reports);
                setReportsByUser(response.reports_by_user);
                setOrganization(response.organization);
                setMonthlyCredits(response.monthly_credits);
                setCreditPeriod(response.credit_period);
                setCreditsConsumed(response.credits_consumed);

                if (response.monthly_credits !== null && response.credits_consumed !== null && response.monthly_credits !== 0) {
                    const creditUsage = Math.floor((response.credits_consumed / response.monthly_credits) * 100);
                    setCreditsConsumedPercent(creditUsage);
                }

                const data = {
                    labels: Object.keys(response.reports_by_day),
                        datasets: [
                            {
                                id: 1,
                                label: '',
                                data: Object.values(response.reports_by_day),
                                backgroundColor: 'rgba(33, 37, 41, 1)',
                                borderColor: 'rgba(33, 37, 41, 1)',
                                borderWidth: 0,
                                borderRadius: 3
                            },
                        ],
                };
                setReportsByDay(data);

            } catch (error) {
                console.log(error);
            } finally {
                setLoadingData(false);
            }
        }
        fetchUsage();
    }, []);

    const now = new Date();
    const thirtyDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);

    const options = {
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'ddd D MMM',
                },
                title: {
                    display: true,
                },
                min: thirtyDaysAgo,
                max: now,
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                suggestedMax: 2,
                title: {
                    display: false,
                },
                ticks: {
                    display: true,
                    stepSize: 1
                },
                beforeBuildTicks: function(axis) {
                    if (axis.max >= 30) {
                        axis.options.ticks.stepSize = 10;
                    } else if (axis.max >= 20) {
                        axis.options.ticks.stepSize = 5;
                    } else if (axis.max >= 12) {
                        axis.options.ticks.stepSize = 3;
                    } else if (axis.max >= 6) {
                        axis.options.ticks.stepSize = 2;
                    }
                },
                grid: {
                    display: true,
                },
            },
        },
        plugins: {
            datalabels: {
                display: true,
                align: 'center',
                color: 'white',
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: false,
                padding: 9, // Adjust padding for more space around the text
            },
        },
    };

    if (loadingData) {
        return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
    }

    return (
        <RequireLogin redirect>
            <PageWrapper title="Account Usage">
                <Row>
                    <Col sm={12} md={4}>
                        <div className="mb-3">
                            <Card>
                                <Card.Header>Summary</Card.Header>
                                    <Card.Body>
                                        <Container>
                                            <h6>Over the past 30 days:</h6>
                                            <ul className="list-unstyled usage-list">
                                                <li><FontAwesomeIcon icon={faUser} className="usage-icon" /> Your usage: <strong>{userReports}</strong> reports</li>
                                                <li><FontAwesomeIcon icon={faBuilding} className="usage-icon" /> {organization}: <strong>{totalReports}</strong> reports</li>
                                            </ul>
                                        </Container>
                                    </Card.Body>
                            </Card>
                        </div>
                        { monthlyCredits !== null && (
                            <Card className="mb-3 mb-xl-0">
                                <Card.Header>Monthly Usage Limit</Card.Header>
                                    <Card.Body>
                                        <Container>
                                            <ProgressBar className="mb-3">
                                                <ProgressBar style={{ minWidth: 20 }} now={creditsConsumedPercent} variant="dark" label={`${creditsConsumedPercent}%`} />
                                            </ProgressBar>
                                            <ul className="list-unstyled usage-list">
                                                <li><FontAwesomeIcon icon={faCalendar} className="usage-icon" /> Current period: {creditPeriod}</li>
                                                <li><FontAwesomeIcon icon={faCoins} className="usage-icon" /> Monthly credits: {monthlyCredits}</li>
                                                <li><FontAwesomeIcon icon={faPieChart} className="usage-icon" /> Credits used: {creditsConsumed}</li>
                                            </ul>
                                        </Container>
                                    </Card.Body>
                            </Card>
                        )}
                    </Col>
                    <Col sm={12} md={8}>
                        <Card className="mb-4">
                            <Card.Header>Usage Details - Last 30 Days</Card.Header>
                            <Card.Body>
                                <Container>
                                <Row className="gx-3 mb-3 usage-table-container">
                                    <h6>{organization} Leaderboard</h6>
                                    <Table striped bordered className="small">
                                        <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Reports Generated</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {reportsByUser.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.email}</td>
                                                    <td>{item.num_reports}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row>
                                <Row className="gx-3">
                                    <h6>Reports Generated by Day</h6>
                                    <Bar data={reportsByDay} options={options} />
                                </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </PageWrapper>
        </RequireLogin>
    )
}

export default AccountPage;