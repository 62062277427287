import React, {useState} from 'react';
import JobsTable from "../../components/JobsTable/JobsTable";
import {useParams} from 'react-router-dom';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const BatchReportListPage = () => {
  const { page_num } = useParams();
  const initialPageNum = page_num !== undefined ? parseInt(page_num, 10) : 1;
  const [startingPage, setStartingPage] = useState(initialPageNum);

  return (
    <RequireLogin redirect>
      <PageWrapper title="Batch Report Jobs">
        <div className="mb-3">
          <Link to="/batch-report-builder">
            <Button variant="outline">
              <FontAwesomeIcon icon={faPlus} className="me-1" /> New Batch Job
            </Button>
          </Link>
        </div>
        <Row>
          <Col className="mb-3 mb-lg-5">
            <Card className="position-relative table-card">
              <JobsTable pageNum={startingPage} reportsPerPage={10} cardFooter={true} jobType="Batch Report" />
            </Card>
          </Col>
        </Row>
      </PageWrapper>
    </RequireLogin>
  );
};

export default BatchReportListPage;