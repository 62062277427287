import { useState, useEffect } from 'react';
import { getReportLayouts } from '../services/reports';

const useReportLayouts = (isAuthenticated) => {
  const [layoutID, setLayoutID] = useState('');
  const [layoutOptions, setLayoutOptions] = useState([]);

  useEffect(() => {
    const fetchLayouts = async () => {
      try {
        const response = await getReportLayouts();
        const layouts = response.layouts.map((layout) => ({
          label: layout.name,
          value: layout.id
        }));
        setLayoutOptions(layouts);
        if (layouts.length > 0) {
          setLayoutID(layouts[0].value);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isAuthenticated) {
      fetchLayouts();
    }
  }, [isAuthenticated]);

  return { layoutID, setLayoutID, layoutOptions };
};

export default useReportLayouts; 