import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './BatchEmailJobPage.css';
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import { getBatchJob, deleteJob } from '../../services/batchEmails';
import Spinner from "../../components/LoadingAnimations/Spinner";
import { Card, Table, Container, Row, Col, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faCircleInfo, faEnvelope, faMagnifyingGlass, faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import GenericModal from "../../components/GenericModal/GenericModal";
import JobStatusBadge from '../../components/JobStatusBadge/JobStatusBadge';
import IconTooltipButton from '../../components/Buttons/IconTooltipButton';

const BatchEmailJobPage = () => {
  const { id } = useParams();
  const [loadingJob, setLoadingJob] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);
  const [childJobDetails, setChildJobDetails] = useState([]);
  const navigate = useNavigate();
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await getBatchJob(id);
        const { child_jobs, ...remainingDetails } = response;
        setChildJobDetails(child_jobs || []);
        setJobDetails(remainingDetails);
      } catch (error) {
        navigate('/404')
      } finally {
        setLoadingJob(false);
      }
    };
    fetchJob();
  }, [id]);

  if (loadingJob) {
    return (<RequireLogin redirect><PageWrapper title=""><Spinner /></PageWrapper></RequireLogin>);
  }

  const fractionToPercentage = (numerator, denominator) => {
    const percentage = Math.round((numerator / denominator) * 100);
    return isNaN(percentage) ? 0 : percentage;
  }

  const handleDeleteJob = async () => {
    try {
      await deleteJob(id);
      // Job deleted successfully, go to job list page
      navigate('/reports/jobs');
    } catch (error) {
      console.error('Failed to delete job', error);
    }
  }

  const renderLogMessages = (messages) => {
    if (!messages || messages.length === 0) {
      return <div>No log messages</div>;
    }
    return (
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    );
  };

  const handleShowModalMessage = (message, title) => {
    setModalTitle(title);
    setModalMessage(message);
    setShowModalMessage(true);
  };

  return (
    <RequireLogin redirect>
      <PageWrapper title={`Batch Email Job: ${jobDetails.date}`}>
        <Container>
          <Button variant="danger" className="btn-md mb-3" onClick={handleDeleteJob}>
            <FontAwesomeIcon icon={faTrashCan} className="me-1" /> Delete
          </Button>
          <Card className="mb-4">
            <Card.Body className="pt-4 px-4">
              <Row>
                <Col md={6}>
                  <div className="details-grid mb-3">
                    <div className="label">Job Name:</div>
                    <div className="value">{jobDetails.title}</div>
                    <div className="label">Date:</div>
                    <div className="value">{jobDetails.date}</div>
                    <div className="label">User:</div>
                    <div className="value">{jobDetails.user_email}</div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="details-grid mb-3">
                    <div className="label">Status:</div>
                    <div className="value d-inline-flex align-items-center">
                      <JobStatusBadge status={jobDetails.status} />
                      {jobDetails.status === 'failure' && jobDetails.failure_message && (
                        <FontAwesomeIcon 
                          icon={faCircleInfo} 
                          className="ms-1" 
                          onClick={() => handleShowModalMessage(jobDetails.failure_message, 'Failure message')}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </div>
                    <div className="label">Progress:</div>
                    <div className="value">
                      <ProgressBar variant="dark"
                      now={fractionToPercentage(jobDetails.progress, jobDetails.progress_total)} 
                      label={`${fractionToPercentage(jobDetails.progress, jobDetails.progress_total)}%`}
                      style={{ minWidth: '100px', height: '20px'}}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
                      
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Title</th>
                <th className="text-center">Status</th>
                <th className="text-center">Progress</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {childJobDetails.map((childJob) => (
                <tr key={childJob.id}>
                  <td>{childJob.title}</td>
                  <td className="text-center">
                    <div className="d-inline-flex align-items-center">
                      <JobStatusBadge status={childJob.status} />
                      {childJob.status === 'failure' && childJob.failure_message && (
                        <FontAwesomeIcon 
                          icon={faCircleInfo} 
                          className="ms-1" 
                          onClick={() => handleShowModalMessage(childJob.failure_message, 'Failure message')}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <ProgressBar 
                      variant="dark"
                      now={fractionToPercentage(childJob.progress, childJob.progress_total)} 
                      label={`${fractionToPercentage(childJob.progress, childJob.progress_total)}%`}
                      style={{ minWidth: '100px', height: '20px'}}
                    />
                  </td>
                  <td className="text-center">
                    <IconTooltipButton tooltipText="View job inputs" className="py-0 px-1" onClick={() => handleShowModalMessage(<pre>{JSON.stringify(childJob.data, null, 2)}</pre>, 'Job inputs')}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </IconTooltipButton>
                    <IconTooltipButton tooltipText="View log messages" className="py-0 px-1" onClick={() => handleShowModalMessage(renderLogMessages(childJob.messages), 'Job progress log')}>
                      <FontAwesomeIcon icon={faLaptopCode} />
                    </IconTooltipButton>
                    {childJob.email_id && (
                      <IconTooltipButton tooltipText="Go to email" className="py-0 px-1" onClick={() => navigate(`/email/${childJob.email_id}`)}>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </IconTooltipButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <GenericModal 
            show={showModalMessage}
            onHide={() => setShowModalMessage(false)}
            title={modalTitle}
            size="lg"
          >
            {modalMessage}
          </GenericModal>          
        </Container>
      </PageWrapper>
    </RequireLogin>
  );
};

export default BatchEmailJobPage;