import httpClient from './httpClient';

const batchReportsUrl = '/api/reports/batch';

export const createBatchJob = async (data) => {
  try {
    const response = await httpClient.post(`${batchReportsUrl}/job`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to create batch report job');
    }
  }
};

export const startBatchJob = async (data) => {
  try {
    const response = await httpClient.post(`${batchReportsUrl}/start`, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to initiate job');
    }
  }
};

export const getBatchJob = async (id) => {
  try {
    const response = await httpClient.get(`${batchReportsUrl}/job/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);  // Throw error received in backend
    } else {
      throw new Error('Failed to get job');
    }
  }
};

export const getJobsList = async ({ page = 1, per_page = 25}) => {
  try {
    const response = await httpClient.get(`${batchReportsUrl}/jobs?page=${page}&per_page=${per_page}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch job list');
  }
};

export const deleteJob = async (jobId) => {
  try {
    const response = await httpClient.delete(`${batchReportsUrl}/job/${jobId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete job');
  }
};