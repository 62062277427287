import React, { useState, useEffect, useRef } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { faXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";
import CardShimmer from "../LoadingAnimations/CardShimmer";
import DealIcon from "../DealIcon/DealIcon";
import { getDealSearchResults } from "../../services/companies";
import { debounce } from 'lodash';
import './SelectDeal.css';

const SelectDeal = ({ onSelect, selectedDeal = null }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dealData, setDealData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [focusedItem, setFocusedItem] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const inputRef = useRef(null);
  const searchBoxRef = useRef(null);
  const dropdownRef = useRef(null);

  const [dropdownStyles, setDropdownStyles] = useState({});

  const fetchData = async () => {
    if (searchQuery) {
      const response = await getDealSearchResults({ page: 1, per_page: 5, query: searchQuery });
      const { deals } = response;
      setDealData(deals);
    } else {
      setDealData([]);
    }
    setSearching(false);
  };

  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    if (!searchQuery) setFocusedItem(-1);
    if (!searching) setSearching(true);
    debouncedFetchData();
    return () => debouncedFetchData.cancel();
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showResults && searchBoxRef.current && dropdownRef.current) {
      // Find how far from the top of the page the search box is.
      const boundingRect = searchBoxRef.current.getBoundingClientRect();

      // Distance from the bottom of the page to the bottom of the input box:
      const spaceBelow = window.innerHeight - boundingRect.bottom;

      // Provide some buffer so it doesn't bump right into the edge (e.g., 20px).
      const buffer = 20;
      const maxDropdownHeight = Math.max(spaceBelow - buffer, 100); 
        // fallback to e.g. 100px if spaceBelow < buffer

      // Adjust the dropdown's maxHeight. Also ensure scrolling is enabled.
      setDropdownStyles({
        maxHeight: `min(320px, ${maxDropdownHeight}px)`,
        overflowY: 'auto',
      });
    }
  }, [showResults, searchQuery]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedItem((prev) => (prev < dealData.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedItem((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter' && dealData.length > 0 && focusedItem >= 0) {
      handleDealSelect(dealData[focusedItem]);
    } else if (e.key === 'Escape') {
      setFocusedItem(-1);
      setShowResults(false);
    }
  };

  const handleDealSelect = (deal) => {
    onSelect(deal);
    setSearchQuery("");
    setShowResults(false);
  };

  return (
    <div ref={searchBoxRef} className="select-deal-box">
      <Form className="search-form">
        <Form.Control
          ref={inputRef}
          type="text"
          placeholder={selectedDeal ? selectedDeal.name : "Search for a company..."}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setShowResults(true);
          }}
          onFocus={() => setShowResults(true)}
          autoComplete="off"
          onKeyDown={handleKeyDown}
        />
        {searchQuery && (
          <Button
            variant="link"
            onClick={() => setSearchQuery("")}
            className="search-btn search-btn-cross"
          >
            <FontAwesomeIcon className="small" icon={faXmark} />
          </Button>
        )}
        <Button variant="link" className="search-btn search-btn-search">
          <FontAwesomeIcon className="small" icon={faSearch} />
        </Button>
      </Form>
      {showResults && searchQuery && (
        <div
          className="search-box-results small"
          ref={dropdownRef}
          style={dropdownStyles}
        >
          <div className="results-list">
            {dealData.length !== 0 ? (
              <ListGroup className="list-group-flush search-list">
                {dealData.map((deal, index) => (
                  <Button
                    key={deal.id}
                    variant="search-result"
                    onClick={() => handleDealSelect(deal)}
                    className="text-start"
                  >
                    <ListGroup.Item
                      className={`d-flex align-items-center clearfix ${
                        index === focusedItem ? "focused" : ""
                      }`}
                    >
                      <DealIcon
                        alt={`${deal.name} logo`}
                        icon={deal.icon}
                        sizePixels={48}
                        className="deal-logo me-3 flex-shrink-0"
                      />
                      <div className="flex-fill-horizontal">
                        <strong className="small">{deal.name}</strong>
                        <br />
                        <span className="small">{deal.identifier}</span>
                      </div>
                    </ListGroup.Item>
                  </Button>
                ))}
              </ListGroup>
            ) : searching ? (
              <div>
                <CardShimmer
                  heightPixels={64}
                  lines={1}
                  paddingPercent={15}
                />
              </div>
            ) : (
              <div className="my-3 mx-3">No results</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectDeal; 