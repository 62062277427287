import React, {useState} from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Form} from "react-bootstrap";
import {Dropdown} from "react-bootstrap";
import './Combobox.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const Combobox = (
  {
    options,
    selectedItem,
    setSelectedItem,
    placeholder = "Select",
    searchPlaceholder = "Search...",
    className = "",
    buttonClassName = "",
    dropdownClassName = "",
    itemClassName = "",
    width = "250px"
  }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownSelect = (eventKey, event) => {
    setSelectedItem(eventKey);
    setSearchTerm("");
    setIsOpen(false);
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  // Filter options based on search term and map to a displayable list
  const filteredOptions = options.filter(option =>
    typeof option.label === 'string' && option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Find the label for the current selected item
  const selectedLabel = options.find(option => option.value === selectedItem)?.label || placeholder;

  const handleKeyDown = (event) => {
    // Check if the Escape key was pressed
    if (event.key === 'Escape') {
      // Prevent the default browser behavior for the Escape key
      event.preventDefault();
      // Stop the event from bubbling up to parent elements
      // This prevents Bootstrap's native dropdown code from trying to handle the event
      // and causing the "Cannot read properties of undefined" error
      event.stopPropagation();
      // Manually close the dropdown using our controlled state
      setIsOpen(false);
    }
  };

  return (
    <>
    <ButtonGroup className={`combobox ${className}`} style={{width: width}}>
      <Dropdown 
        as={ButtonGroup} 
        onSelect={handleDropdownSelect} 
        show={isOpen} 
        onToggle={toggleOpen} 
        style={{width: width}}
        onKeyDown={handleKeyDown}
      >
        <Dropdown.Toggle
          variant="outline"
          className={`dropdown-button d-flex justify-content-between align-items-center ${buttonClassName}`}
        >
          <span className="overflow-hidden fw-medium">{selectedLabel}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`pt-0 shadow mt-1 pb-1 ${dropdownClassName}`}>
          <div className={`dropdown-animation ${isOpen ? 'dropdown-enter' : ''}`}>
          <Form.Group className="d-flex justify-content-between align-items-center">
            <FontAwesomeIcon icon={faSearch} className="ps-3 pe-2 small text-secondary" />
            <Form.Control
              className="ps-0 pe-3 flex-fill-horizontal"
              type="text"
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoComplete="off"
            />
          </Form.Group>
          <hr className="mb-1 mt-0" />
          <div className="px-1 dropdown-items narrow-scrollbar">
            { filteredOptions.length === 0 && (<div className="px-3 py-1">No results</div>)}
            {filteredOptions.map((option) => (
              <Dropdown.Item key={option.value} eventKey={option.value} className={itemClassName}>
                {option.label}
              </Dropdown.Item>
            ))}
          </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </ButtonGroup>
    </>
  );
}

export default Combobox;