import React, { useState, useEffect, useCallback } from 'react';
import GenericTable from "../GenericTable/GenericTable";
import {getTransactions} from "../../services/companies";
import InfoPopupIcon from "../Buttons/InfoPopupIcon";
import {CrunchbaseIcon, PitchbookIcon} from "../SocialIcons/SocialIcons";
import './TransactionsTable.css';

const TransactionsTable = (
  {
    companyId
  }) => {
  const [transactionsData, setTransactionsData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getTransactions({companyId: companyId});
        setTransactionsData(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(num);
  }

  const columns = [
    {
      header: 'Date',
      field: 'date',
      render: (transaction) => (
        <span className="transaction-date d-inline-flex align-items-center">
          {transaction.source === "PitchBook" ? (
              <PitchbookIcon url={`${transaction.source_url}`} />
          ) : transaction.source === "Crunchbase" ? (
            <>
              <CrunchbaseIcon url={`${transaction.source_url}`} />
            </>
          ) : (<></>)}
          {transaction.date}
        </span>
      )
    },
    {
      header: 'Name',
      field: 'name',
      render: (transaction) => (
        <>
          {transaction.target && (
            <>
              {transaction.target}
            </>
          )}
          {transaction.round_name && (
            <>
              {transaction.target ? ' - ' : ''}{transaction.round_name}
            </>
          )}
          {transaction.note && (
            <>
              &nbsp;<InfoPopupIcon infoText={transaction.note} />
            </>
          )}
        </>
      )
    },
    {
      header: 'Type',
      field: 'type'
    },
    {
      header: 'Amount',
      field: 'amount',
      render: (transaction) => (
        <>
          {transaction.amount && (
            <>
              {transaction.amount_currency} {formatNumber(transaction.amount / 1000000)}M
            </>
          )}
        </>
      )
    },
    {
      header: 'Valuation',
      field: 'post_money_valuation',
      render: (transaction) => (
        <>
          { transaction.post_money_valuation && (
            <>
              {transaction.valuation_currency} {formatNumber(transaction.post_money_valuation / 1000000)}M
            </>
          ) }
        </>
      )
    },
    {
      header: 'Lead Investors',
      field: 'lead_investors'
    }
  ];

  return (
    <>
      <GenericTable
        columns={columns}
        data={transactionsData}
        page={1}
        totalPages={1}
        onChangePage={null}
        isLoading={!transactionsData}
        filters={['source']}
        footerClassName="d-none"
        hideEmptyCols={true}
      />
    </>
  );
};

export default TransactionsTable;