import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReportBuilderPage from './pages/ReportBuilderPage/ReportBuilderPage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import ReportListPage from "./pages/ReportListPage/ReportListPage";
import ReportPage from "./pages/ReportPage/ReportPage";
import AccountPage from "./pages/AccountPage/AccountPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import UsagePage from "./pages/UsagePage/UsagePage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import { AuthProvider } from './contexts/AuthContext';
import { NotificationsProvider } from "./contexts/NotificationsContext";
import { ActivatedFeaturesProvider } from "./contexts/ActivatedFeaturesContext";
import EmailPage from "./pages/EmailPage/EmailPage";
import './App.css';
import EmailTemplatePage from "./pages/EmailTemplatePage/EmailTemplatePage";
import EmailListPage from "./pages/EmailListPage/EmailListPage";
import ReportEditorPage from "./pages/ReportPage/ReportEditorPage";
import DealPage from "./pages/DealPage/DealPage";
import DealListPage from "./pages/DealListPage/DealListPage";
import Sandbox from "./pages/Sandbox/Sandbox";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import BatchReportBuilderPage from "./pages/BatchReportBuilderPage/BatchReportBuilderPage";
import BatchReportJobPage from "./pages/BatchReportJobPage/BatchReportJobPage";
import BatchReportListPage from "./pages/BatchReportListPage/BatchReportListPage";
import BatchEmailBuilderPage from "./pages/BatchEmailBuilderPage/BatchEmailBuilderPage";
import BatchEmailListPage from "./pages/BatchEmailListPage/BatchEmailListPage";
import BatchEmailJobPage from './pages/BatchEmailJobPage/BatchEmailJobPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <ActivatedFeaturesProvider>
          <NotificationsProvider>
            <Routes>

              {/* Home Page Route */}
              <Route exact path="/" element={<DashboardPage />} />

              {/* Sandbox Route - For Testing */}
              <Route exact path="/sandbox" element={<Sandbox />} />

              {/* Auth Routes */}
              <Route exact path="/login" element={<LoginPage />} />
              <Route exact path="/register" element={<RegisterPage />} />

              {/* Deal Routes */}
              <Route exact path="/deals" element={<DealListPage />} />
              <Route path="/deals/:page_num" element={<DealListPage />} />
              <Route path="/deal/:id" element={<DealPage />} />

              {/* Report Routes */}
              <Route exact path="/report-builder" element={<ReportBuilderPage />} />
              <Route exact path="/reports" element={<ReportListPage />} />
              <Route path="/reports/:page_num" element={<ReportListPage />} />
              <Route path="/report/:id" element={<ReportPage />} />
              <Route path="/report/edit/:id" element={<ReportEditorPage />} />

              {/* Batch Report Routes */}
              <Route exact path="/batch-report-builder" element={<BatchReportBuilderPage />} />
              <Route exact path="/reports/jobs" element={<BatchReportListPage />} />
              <Route path="/reports/jobs/:page_num" element={<BatchReportListPage />} />
              <Route path="/reports/job/:id" element={<BatchReportJobPage />} />

              {/* Email Routes */}
              <Route exact path="/emails" element={<EmailListPage />} />
              <Route path="/emails/:page_num" element={<EmailListPage />} />
              <Route path="/email/:id" element={<EmailPage />} />
              <Route exact path="/email-template" element={<EmailTemplatePage />} />
              <Route path="/email-template/:id" element={<EmailTemplatePage />} />

              {/* Batch Email Routes */}
              <Route exact path="/batch-email-builder" element={<BatchEmailBuilderPage />} />
              <Route exact path="/emails/jobs" element={<BatchEmailListPage />} />
              <Route path="/emails/jobs/:page_num" element={<BatchEmailListPage />} />
              <Route path="/emails/job/:id" element={<BatchEmailJobPage />} />

              {/* News Page Route */}
              <Route path="/news/:dealId?" element={<NewsPage />} />

              {/* Account and Settings Routes */}
              <Route exact path="/account" element={<AccountPage />} />
              <Route exact path="/settings" element={<SettingsPage />} />
              <Route exact path="/usage" element={<UsagePage />} />

              {/* Error Page Route */}
              <Route path="/404" element={<NotFoundPage />} />
              <Route path="*" element={<NotFoundPage />} />

            </Routes>
          </NotificationsProvider>
        </ActivatedFeaturesProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;