import React, { useState, useEffect, useRef } from 'react';
import RequireLogin from "../../components/RequireLogin/RequireLogin";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import './DashboardPage.css';
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faAward, faCalendar,
  faCalendarAlt,
  faChartLine,
  faCirclePlus,
  faDollarSign,
  faEnvelope,
  faEnvelopeOpen,
  faFileAlt,
  faHandshake,
  faPeopleGroup
} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import {Link, useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {getUsageSummary} from "../../services/user";
import NewsList from "../../components/NewsList/NewsList";
import SidebarNavigation from "../../components/SidebarNavigation/SidebarNavigation";

const DashboardPage = () => {
  const [usage, setUsage] = useState(null);
  const sectionRefs = {
    funding: useRef(null),
    financials: useRef(null),
    acquisitions: useRef(null),
    execChanges: useRef(null),
    awards: useRef(null),
    events: useRef(null)
  }
  const navigate = useNavigate();

  const fetchUsageSummary = async () => {
    const response = await getUsageSummary({days: 30});
    setUsage(response);
  }

  useEffect(() => {
    if (!usage) fetchUsageSummary();
  }, []);

  return (
    <RequireLogin redirect>
      <PageWrapper title="Control Tower">

        <Row className="dashboard d-flex">
          <Col xs={6} lg={3} className="mb-3">
            <Link to="/report-builder" className="text-decoration-none">
              <Card className="p-2 action-card">
                <Card.Header>
                  <div className="d-flex justify-content-between align-items-center text-nowrap">
                    <span>Build a report</span>
                    <span><FontAwesomeIcon className="ms-3 d-none d-sm-inline-block" icon={faFileAlt} /></span>
                  </div>
                </Card.Header>
                <Card.Body className="px-4 text-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="fs-2 action-icon" />
                  <div className="small mt-3">New report</div>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col xs={6} lg={3} className="mb-3">
            <Link to="/batch-email-builder" className="text-decoration-none">
              <Card className="p-2 action-card">
                <Card.Header>
                  <div className="d-flex justify-content-between align-items-center text-nowrap">
                    <span>Draft an email</span>
                    <span><FontAwesomeIcon className="ms-3 d-none d-sm-inline-block" icon={faEnvelope} /></span>
                  </div>
                </Card.Header>
                <Card.Body className="px-4 text-center">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="fs-2 action-icon" />
                  <div className="small mt-3">New email</div>
                </Card.Body>
              </Card>
            </Link>
          </Col>

          <Col lg={6} className="mb-3">
            <Card className="p-2 action-card">
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center text-nowrap">
                  <span>Last 30 days</span>
                  <span><FontAwesomeIcon className="ms-3 d-none d-sm-inline-block" icon={faCalendarAlt} /></span>
                </div>
              </Card.Header>
              <Card.Body className="px-4 py-0 text-center">
                <Row>
                  <Col xs={4}>
                    { usage?.deals !== undefined && (
                    <>
                      <div className="small">Deals</div>
                      <span className="fs-2 fw-semibold">+{usage?.deals}</span>
                      <div className="smaller text-secondary mt-1">{usage?.deal_growth} MoM</div>
                    </>
                    )}
                  </Col>
                  <Col xs={4}>
                    { usage?.reports !== undefined && (
                      <>
                        <div className="small">Reports</div>
                        <span className="fs-2 fw-semibold">+{usage?.reports}</span>
                        <div className="smaller text-secondary mt-1">{usage?.report_growth} MoM</div>
                      </>
                    )}
                  </Col>
                  <Col xs={4}>
                    { usage?.reports !== undefined && (
                      <>
                        <div className="small">Emails</div>
                        <span className="fs-2 fw-semibold">+{usage?.emails}</span>
                        <div className="smaller text-secondary mt-1">{usage?.email_growth} MoM</div>
                      </>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5 className="mt-3">Actionable News</h5>
            <hr className="hr-title" />
            <p className="fw-light">
              Review the latest pipeline updates below. Click
              &nbsp;<FontAwesomeIcon icon={faEnvelope} />&nbsp;
              to generate an email to the company about the associated news item.
            </p>
          </Col>
        </Row>

        <div className="dashboard d-flex">

          <div className="menu-sidebar mb-3 d-flex flex-column flex-shrink-0">
            <Card className="menu-card sticky-top px-0 px-lg-2 py-2">
              <Card.Header>
                <span className="d-none d-lg-inline-block">News Categories</span>
                <span className="d-lg-none">Nav</span>
              </Card.Header>
              <Card.Body className="pt-0 px-2 px-lg-1 small">
                <SidebarNavigation
                  sections={{
                  funding: {ref: sectionRefs.funding, label: "Funding", icon: faDollarSign},
                  financials: {ref: sectionRefs.financials, label: "Financials", icon: faChartLine},
                  acquisitions: {ref: sectionRefs.acquisitions, label: "Acquisitions", icon: faHandshake},
                  execChanges: {ref: sectionRefs.execChanges, label: "Exec Changes", icon: faPeopleGroup},
                  awards: {ref: sectionRefs.awards, label: "Awards", icon: faAward},
                  events: {ref: sectionRefs.events, label: "Events", icon: faCalendar}
                }} />
              </Card.Body>
            </Card>
          </div>

          <div className="ms-4 d-flex flex-column flex-grow-1">
            <Card className="px-3 py-2">
              <Card.Body>

                <div ref={sectionRefs.funding} className="mb-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <h6>Funding Rounds</h6>
                    <Button onClick={() => navigate("/news/")} variant="outline" className="py-1">
                      <span className="small">See all news <FontAwesomeIcon className="ms-1" icon={faArrowRight} /></span>
                    </Button>
                  </div>
                  <hr className="hr-title mt-2" />
                  <NewsList page={1} perPage={5} category="funding" onePerCompany={true} showMoreAt={2} />
                </div>

                <div ref={sectionRefs.financials} className="mb-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <h6>Financial Results</h6>
                  </div>
                  <hr className="hr-title mt-2" />
                  <NewsList page={1} perPage={5} category="financial" onePerCompany={true} showMoreAt={2} />
                </div>

                <div ref={sectionRefs.acquisitions} className="mb-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <h6>Acquisitions</h6>
                  </div>
                  <hr className="hr-title mt-2" />
                  <NewsList page={1} perPage={5} category="acquisition" onePerCompany={true} showMoreAt={2} />
                </div>

                <div ref={sectionRefs.execChanges} className="mb-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <h6>Executive Changes</h6>
                  </div>
                  <hr className="hr-title mt-2" />
                  <NewsList page={1} perPage={5} category="executive" onePerCompany={true} showMoreAt={2} />
                </div>

                <div ref={sectionRefs.awards} className="mb-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <h6>Company Awards</h6>
                  </div>
                  <hr className="hr-title mt-2" />
                  <NewsList page={1} perPage={5} category="award" onePerCompany={true} showMoreAt={2} />
                </div>

                <div ref={sectionRefs.events} className="mb-4">
                  <div className="d-flex justify-content-between align-items-end">
                    <h6>Conferences & Events</h6>
                  </div>
                  <hr className="hr-title mt-2" />
                  <NewsList page={1} perPage={5} category="event" onePerCompany={true} showMoreAt={2} />
                </div>

              </Card.Body>
            </Card>
          </div>
        </div>

      </PageWrapper>
    </RequireLogin>

  );
}

export default DashboardPage;