import React, { useState, useEffect, useCallback } from 'react';
import {Link} from 'react-router-dom';
import { getJobsList as getBatchReportsList, deleteJob as deleteBatchReportJob } from '../../services/batchReports';
import { getJobsList as getBatchEmailsList, deleteJob as deleteBatchEmailJob } from '../../services/batchEmails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import IconTooltipButton from "../Buttons/IconTooltipButton";
import DisappearingToast from "../DisappearingToast/DisappearingToast";
import GenericTable from "../GenericTable/GenericTable";
import JobStatusBadge from '../JobStatusBadge/JobStatusBadge';

const ReportJobsTable = (
  {
    pageNum=1,
    jobsPerPage=10,
    cardFooter=false,
    jobType="Batch Report"  // Batch Report or Batch Email
  }) => {
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [jobsData, setJobsData] = useState(null);
  const [deletedJobName, setDeletedJobName] = useState("");
  const [showDeletedToast, setShowDeletedToast] = useState(false);

  const getJobsList = jobType === "Batch Report" ? getBatchReportsList : getBatchEmailsList;
  const deleteJob = jobType === "Batch Report" ? deleteBatchReportJob : deleteBatchEmailJob;
  const urlRoot = jobType === "Batch Report" ? "/reports" : "/emails";

  const fetchData = useCallback(async () => {
    try {
      const response = await getJobsList({page: currentPage, per_page: jobsPerPage});
      setJobsData(response);
    } catch (error) {
      console.log(error);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setCurrentPage(pageNum);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteJob = async (jobId, jobName) => {
    try {
      await deleteJob(jobId);
      setDeletedJobName(jobName);
      setShowDeletedToast(true);
      // Report deleted successfully, refetch report list
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const total_pages = jobsData?.total_pages || 1;
  const current_page = jobsData?.current_page || 1;
  const reports = jobsData?.jobs || [];

  const columns = [
    {
      header: 'Title',
      field: 'name',
      render: (job) => (
      <div className="d-flex align-items-center">
        <Link to={`${urlRoot}/job/${job.id}`}>{job.title}</Link>
      </div>
    )},
    {
      header: 'Date',
      field: 'date'
    },
    {
      header: 'User',
      field: 'user_email'
    },
    {
      header: 'Status',
      field: 'status',
      className: 'text-center',
      render: (job) => (
        <JobStatusBadge status={job.status} />
      )},
    {
      header: 'Delete',
      field: 'delete',
      className: 'text-center',
      render: (job) => (
      <IconTooltipButton tooltipText="Delete job" className="py-0 px-1" onClick={() => handleDeleteJob(job.id, job.title)}>
        <FontAwesomeIcon icon={faTrashCan} />
      </IconTooltipButton>
    )},
  ];

  return (
    <>
      <GenericTable
        columns={columns}
        data={reports}
        page={current_page}
        totalPages={total_pages}
        onChangePage={handlePageChange}
        isLoading={!jobsData}
        footerClassName={cardFooter ? ("card-footer") : ("")}
      />
      <DisappearingToast title="Deleted!" text={`Deleted: ${deletedJobName}`} show={showDeletedToast} setShow={setShowDeletedToast} />
    </>
  );
};

export default ReportJobsTable;